<template>
  <div>
    <b-row>
      <b-col cols="8">
        <div class="areawrap">
          <b-row>
            <b-col cols="12">
              <div class="d-flex align-items-end px-4">
                <h2 class="mb-0 mr-4">
                  Corporate Information
                </h2>
                <b-link
                  class="text-gray-500"
                  href="#"
                  @click.prevent="openCorporateModal"
                >
                  <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
                </b-link>
                <span class="text-primary font-size-sm ml-auto">
                  Date Added
                </span>
                <span class="font-size-base font-weight-normal ml-2">
                  {{ $dayjs(partnerData.created_at).format('MMM D, YYYY') }}
                </span>
              </div>
              <hr>
              <b-form-group label="Corporate Name" label-class="text-primary mb-1">
                {{ partnerData.corporate_name }}

                <span v-if="partnerData.enabled === false" class="d-inline-block bg-danger text-white rounded-pill px-2 py-0">
                  Deactivated
                </span>
                <span
                  v-else-if="partnerData.status === 0"
                  class="d-inline-block bg-primary text-white rounded-pill px-2 py-0 ml-4"
                >
                  Pending
                </span>
                <span v-else class="d-inline-block bg-success text-white rounded-pill px-2 py-0 ml-4">
                  Active
                </span>
              </b-form-group>
              <b-form-group label="Address" label-class="text-primary mb-1">
                <span class="d-block">{{ partnerData.corporate_address }}</span>
                <span class="d-block">{{ partnerData.corporate_city }}, {{ partnerCountry }}</span>
              </b-form-group>
              <b-form-group label="Telephone" label-class="text-primary mb-1">
                {{ partnerData.corporate_telphone }}
              </b-form-group>
              <b-form-group v-if="!isEditOperationType" label="Operation Type" label-class="text-primary mb-1">
                <span
                  v-if="partnerData.distributor"
                  class="d-inline-block bg-info text-white rounded-pill px-2 py-0 mr-2"
                >
                  Distributor
                </span>
                <span
                  v-if="partnerData.service_provide"
                  class="d-inline-block bg-info2 text-white rounded-pill px-2 py-0 mr-2"
                >
                  Service Provider
                </span>
                <b-link
                  v-if="partnerData.status === 1"
                  class="text-gray-500"
                  href="#"
                  @click.prevent="editOperationTypeHandler"
                >
                  <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
                </b-link>
              </b-form-group>
              <template v-else>
                <b-form-group label="Operation Type">
                  <b-form-radio-group v-model="operationType">
                    <b-form-radio :value="0">
                      Service Provider
                    </b-form-radio>
                    <b-form-radio :value="1">
                      Distributor
                    </b-form-radio>
                    <b-form-radio :value="2">
                      Both
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <b-button variant="primary" class="btn-width px-8" @click="submitOperationType">
                  SAVE CHANGES
                </b-button>
              </template>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="6">
              <div class="d-flex align-items-end px-4">
                <h2 class="mb-0 mr-4">
                  Owner Information
                </h2>
                <b-link v-if="partnerData.status === 0" class="text-gray-500" href="#" @click.prevent="openOwnerModal">
                  <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
                </b-link>
              </div>
              <hr>
              <b-form-group label="Owner" label-class="text-primary mb-1">
                {{ partnerData.owner_name || '-' }}
              </b-form-group>
              <b-form-group label="Email" label-class="text-primary mb-1">
                {{ partnerData.owner_email || '-' }}
              </b-form-group>
              <b-form-group label="Telephone" label-class="text-primary mb-1">
                {{ partnerData.owner_extension || '-' }}
              </b-form-group><b-form-group label="Mobile Phone" label-class="text-primary mb-1">
                {{ partnerData.owner_mobile || '-' }}
              </b-form-group>
            </b-col>

            <b-col cols="6">
              <h2 class="px-4">
                Contact
              </h2>
              <hr>
              <b-form-group label="Contact" label-class="text-primary mb-1">
                {{ partnerData.contact_name || '-' }}
              </b-form-group>
              <b-form-group label="Email" label-class="text-primary mb-1">
                {{ partnerData.contact_email || '-' }}
              </b-form-group>
              <b-form-group label="Telephone" label-class="text-primary mb-1">
                {{ partnerData.contact_extension || '-' }}
              </b-form-group>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>

    <corporate-modal ref="corporateModal" @update="init" />
    <owner-modal ref="ownerModal" @update="init" />
  </div>
</template>

<script>
import countryList from '@/common/countryList'
import { getPartnerData, updatePartnerCorporateData } from '@/api/partner'
import corporateModal from '@/components/partner/corporateModal'
import ownerModal from '@/components/partner/ownerModal'

export default {
  name: 'PartnerDetail',
  components: {
    corporateModal,
    ownerModal
  },
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      partnerData: {},
      tabIndex: 0,
      isEditOperationType: false,
      operationTypeData: {
        service_provide: false,
        distributor: true
      }

    }
  },
  computed: {
    partnerCountry () {
      return countryList.find(item => item.value === this.partnerData.corporate_country)?.text
    },
    operationType: {
      get () {
        if (this.operationTypeData.service_provide && this.operationTypeData.distributor) return 2
        else if (this.operationTypeData.service_provide) return 0
        else return 1
      },
      set (value) {
        this.operationTypeData.service_provide = value === 0 || value === 2
        this.operationTypeData.distributor = value === 1 || value === 2
      }
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      await this.getPartnerData()
    },
    changeTabHandler () {
      this.initParams()
      if (this.tabIndex === 0) {
        this.getSpecifyList()
      } else {
        this.getClinicList()
      }
    },
    initParams () {
      this.search = ''
      this.searchName = ''
      this.city = null
      this.field = 'name'
      this.ascending = true
    },
    getPartnerData () {
      return getPartnerData(this.id).then(res => {
        this.partnerData = res.data.data.partner
      })
    },
    openCorporateModal () {
      const data = {
        id: this.id,
        enabled: this.partnerData.enabled,
        corporate_name: this.partnerData.corporate_name,
        corporate_country: this.partnerData.corporate_country,
        corporate_city: this.partnerData.corporate_city,
        corporate_address: this.partnerData.corporate_address,
        corporate_telphone: this.partnerData.corporate_telphone,
        service_provide: this.partnerData.service_provide,
        distributor: this.partnerData.distributor,
        allow_disable: this.partnerData.allow_disable
      }
      this.$refs.corporateModal.show(data)
    },
    openOwnerModal () {
      const data = {
        id: this.id,
        owner_name: this.partnerData.owner_name,
        owner_email: this.partnerData.owner_email,
        owner_mobile: this.partnerData.owner_mobile,
        owner_extension: this.partnerData.owner_extension
      }
      this.$refs.ownerModal.show(data)
    },

    editOperationTypeHandler () {
      this.isEditOperationType = true
      this.operationTypeData.service_provide = this.partnerData.service_provide
      this.operationTypeData.distributor = this.partnerData.distributor
    },
    submitOperationType () {
      const data = Object.assign({}, this.partnerData, this.operationTypeData)
      updatePartnerCorporateData(data).then(() => {
        this.isEditOperationType = false
        this.init()
      })
    }
  }
}
</script>
